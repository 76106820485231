import React from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

export default () => {
  const title = '404'
  const browser = typeof window !== 'undefined' && window
  return (
    browser && (
      <Layout>
        <Helmet title={title} />
        <div className="wrap px-6 mx-auto py-8 md:px-20 md:pt-12 md:pb-32 h-45vw">
          <h1 className="uppercase font-light tracking-widest text-gray-33 text-20 md:text-36">
            404 Page Not Found.
          </h1>
        </div>
      </Layout>
    )
  )
}
